// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpointxs": "0",
	"breakpointsm": "640",
	"breakpointmd": "992",
	"breakpointlg": "1200",
	"breakpointxl": "1440",
	"breakpoint2xl": "1920",
	"breakpoint3xl": "2400",
	"color-black": "#000",
	"color-white": "#fff",
	"color-gray": "#100810",
	"color-primary": "#ff0038",
	"color-cornflower": "#386fea",
	"color-turquoise": "#7effba",
	"color-griffin": "#fefe00",
	"color-rose": "#ff40ad",
	"color-orange": "#ff8900",
	"color-green": "#a3fe53",
	"color-dark": "#100810",
	"color-light": "#e7e6e7"
};
module.exports = ___CSS_LOADER_EXPORT___;
